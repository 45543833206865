import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'início',
    component: () => import('../views/PáginaInicial.vue'),
    meta: {
      title: 'Indigo Softworks | Soluções em tecnologia.',
    },
  },
  {
    path: '/sobre',
    name: 'sobre',
    component: () => import('../views/PáginaSobre.vue'),
    meta: {
      title: 'Indigo Softworks | Soluções em tecnologia.',
    },
  },

  {
    path: '/sobre2',
    name: 'sobre2',
    component: () => import('../views/About.vue'),
    meta: {
      title: 'Indigo Softworks | Soluções em tecnologia.',
    },
  },

  {
    path: '/erro-404',
    name: 'erro-404',
    component: () => import('../views/Erro404.vue'),
    meta: {
      title: 'Indigo Softworks | Soluções em tecnologia.',
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes: [
    ...routes,
    { path: '/:catchAll(.*)', redirect: 'erro-404' },
  ]
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
  window.scrollTo(0, 0)
})

export default router
