import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import CoolLightBox from 'vue-cool-lightbox'
import VueScrollActive from 'vue-scrollactive'
import AOS from 'aos'

// A ORDEM IMPORTA, NÃO ALTERAR
import 'bootstrap/scss/bootstrap.scss'
import 'animate.css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import './assets/scss/style.scss'
import 'aos/dist/aos.css'
import 'bootstrap'

const app = createApp(App)

// eslint-disable-next-line new-cap
app.AOS = new AOS.init({ duration: 800, once: true })

app.use(router)
app.use(CoolLightBox)
app.use(VueScrollActive)
app.use(AOS)

app.mount('#app')
